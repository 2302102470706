import React from "react";
import Layout from "../component/Layouts/Layout";
import styles from "../style/homepage.module.css";
import { AboutUni } from "../Data/AboutUni";
import BotToTop from "../component/BottomToTop";
import UniversityHeading from "../component/Home/University/UniversityHeading";
import FindOutHeading from "../component/AboutUs/FindOutMore/FindOutHeading";
import FindOutMore from "../component/AboutUs/FindOutMore/FindOutMore";
import rectangle from "../assets/images/Rectangle1326.png";
import stylesmodule from "../style/layout.module.css";

const HomePage = () => {
  return (
    <>
      <Layout>
        <table className={stylesmodule.customMenuTable}>
          <tbody>
            <tr>
              {" "}
              <td className="hover-effect">Admission & Fee</td>
              <td rowSpan={8}>
                <img src={rectangle} alt="noimage" />
              </td>
              <td className="hover-effect">G.B. Members</td>
            </tr>
            <tr>
              <td className="hover-effect">Alumni</td>
              <td className="hover-effect">College Committees</td>
            </tr>
            <tr>
              <td className="hover-effect">Facilities</td>
              <td className="hover-effect">HRD - BIhar</td>
            </tr>
            <tr>
              <td className="hover-effect">General Rules</td>
              <td className="hover-effect">UGC</td>
            </tr>
            <tr>
              <td className="hover-effect">Code of Conduct</td>
              <td className="hover-effect">NAAC</td>
            </tr>
            <tr>
              <td className="hover-effect">Code of Ethics</td>
              <td className="hover-effect">Patliputra University</td>
            </tr>
            <tr>
              <td className="hover-effect">Core Value</td>
              <td className="hover-effect">Bihar Intermediate</td>
            </tr>
            <tr>
              <td className="hover-effect">Student Survey</td>
              <td className="hover-effect">Contact</td>
            </tr>
          </tbody>
        </table>
        <BotToTop />
        <UniversityHeading />

        <div className={styles.purposeUniCont}>
          {AboutUni.map((item, index) => (
            <div key={index} className={styles.innerPurposeUniCont}>
              <img src={item.image} alt={item.title} />
              <div className={styles.purposeCardCont}>
                <span>{item.name}</span>
              </div>
            </div>
          ))}
        </div>

        <FindOutHeading />
        <FindOutMore />
      </Layout>
    </>
  );
};

export default HomePage;
