

export const AboutUni=[
  {
    name:"Classroom",
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5vU2TZw3rBnajO0gi8pCn95KJyXKLBvjVzw&usqp=CAU"

},
  {
    name:"Campus",
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1itCKUJtSoARf7QCzzWbDBy9Ynt3QckKNO_DjmEO65lMJsbbp418Xt9oeOc-eb_5YU18&usqp=CAU"

},
  {
    name:"Convocation",
    image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3m6pd8zZCj-Mm_zWZG5xXOhCi5VzqEcicbQ&usqp=CAU"

}
]

