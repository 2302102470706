// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminCalendarContainer {
  overflow-x: auto;
  color: black;
}

.adminCalendarContainer .calendarContent {
  display: flex;
  justify-content: space-between;
}

.adminCalendarContainer .sidebar {
  flex: 1 1 20%;
  padding: 15px;
  border-radius: 4px;
  background: none;
}

.adminCalendarContainer .sidebar h5 {
  margin-bottom: 10px;
}

.adminCalendarContainer .calendar {
  flex: 1 1 100%;
}

.adminCalendarContainer .eventDate:hover {
  background-color: lightblue;
  color: black;
}

.adminCalendarContainer ul {
  list-style-type: none;
  padding: 0;
}

.adminCalendarContainer .eventItem {
  background-color: #3498db;
  margin: 10px 0;
  border-radius: 2px;
  padding: 5px;
  font-size: 12px;
}
.adminCalendarContainer .eventItem div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.adminCalendarContainer .eventTitle {
  font-weight: bold;
}

.adminCalendarContainer.event-Date {
  font-size: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/style/AdminDashboard/Calendar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".adminCalendarContainer {\n  overflow-x: auto;\n  color: black;\n}\n\n.adminCalendarContainer .calendarContent {\n  display: flex;\n  justify-content: space-between;\n}\n\n.adminCalendarContainer .sidebar {\n  flex: 1 1 20%;\n  padding: 15px;\n  border-radius: 4px;\n  background: none;\n}\n\n.adminCalendarContainer .sidebar h5 {\n  margin-bottom: 10px;\n}\n\n.adminCalendarContainer .calendar {\n  flex: 1 1 100%;\n}\n\n.adminCalendarContainer .eventDate:hover {\n  background-color: lightblue;\n  color: black;\n}\n\n.adminCalendarContainer ul {\n  list-style-type: none;\n  padding: 0;\n}\n\n.adminCalendarContainer .eventItem {\n  background-color: #3498db;\n  margin: 10px 0;\n  border-radius: 2px;\n  padding: 5px;\n  font-size: 12px;\n}\n.adminCalendarContainer .eventItem div {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n}\n\n.adminCalendarContainer .eventTitle {\n  font-weight: bold;\n}\n\n.adminCalendarContainer.event-Date {\n  font-size: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
