import AllRoutes from "./AllRoutes/AllRoutes";
import "./App.css";

const App = () => {
  return (
    <>
      <AllRoutes />
    </>
  );
};

export default App;
