// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

table th {
  background-color: #f2f2f2;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

input[type="number"] {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/TeacherDashboard/Marks/TeacherMarksPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb","sourcesContent":["table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\ntable th, table td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\ntable th {\n  background-color: #f2f2f2;\n}\n\ntable tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\ntable tr:hover {\n  background-color: #ddd;\n}\n\ninput[type=\"number\"] {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
