// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminDashboardContainer .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 3rem;
  justify-content: center;
  width: 100%;
  padding: 4rem;
  padding-top: 3.5rem;
}

.adminDashboardContainer .grid-item {
  background-color: #09203e;
  color: #7e9fca;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.adminDashboardContainer .grid-item .icon {
  margin-top: 1rem;
}

.adminDashboardContainer .grid-item .label {
  font-size: 1rem;
}

.adminDashboardContainer .grid-item .count {
  font-size: 1rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .adminDashboardContainer .grid-item {
    width: 70%;
    margin: auto;
  }
  .adminDashboardContainer .grid-item .icon {
    font-size: 40px;
  }

  .adminDashboardContainer .grid-item .label {
    margin-top: 10px;
    font-size: 0.75rem;
  }

  .adminDashboardContainer .grid-item .count {
    font-size: 0.75rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/AdminDashboard/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2DAA2D;EAC3D,SAAS;EACT,uBAAuB;EACvB,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,UAAU;IACV,YAAY;EACd;EACA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".adminDashboardContainer .grid-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));\n  gap: 3rem;\n  justify-content: center;\n  width: 100%;\n  padding: 4rem;\n  padding-top: 3.5rem;\n}\n\n.adminDashboardContainer .grid-item {\n  background-color: #09203e;\n  color: #7e9fca;\n  border-radius: 0.5rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 1rem;\n}\n\n.adminDashboardContainer .grid-item .icon {\n  margin-top: 1rem;\n}\n\n.adminDashboardContainer .grid-item .label {\n  font-size: 1rem;\n}\n\n.adminDashboardContainer .grid-item .count {\n  font-size: 1rem;\n  margin-top: 0.5rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@media screen and (max-width: 768px) {\n  .adminDashboardContainer .grid-item {\n    width: 70%;\n    margin: auto;\n  }\n  .adminDashboardContainer .grid-item .icon {\n    font-size: 40px;\n  }\n\n  .adminDashboardContainer .grid-item .label {\n    margin-top: 10px;\n    font-size: 0.75rem;\n  }\n\n  .adminDashboardContainer .grid-item .count {\n    font-size: 0.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
