// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    border: 1px solid red;
    height: 100vh; 
  }
  
  .content {
    flex: 1 1;
    overflow-x: hidden;
  }
  
  .navbar {
    flex-shrink: 0; 
  }
  `, "",{"version":3,"sources":["webpack://./src/AllRoutes/AllRoutes.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,aAAa;EACf;;EAEA;IACE,SAAO;IACP,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".container {\n    display: flex;\n    border: 1px solid red;\n    height: 100vh; \n  }\n  \n  .content {\n    flex: 1;\n    overflow-x: hidden;\n  }\n  \n  .navbar {\n    flex-shrink: 0; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
